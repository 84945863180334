.navbar{
    height: 50px;
    background-color: #97d362;
    display: flex;
    justify-content: center;
}

.navContainer{
width: 100%;
max-width: 1024px;
color: white;
display: flex;
align-items: center;
justify-content: space-between;
}

.logoContainer{
    display: flex;
    max-width: 200px;
    margin-top: 1%;
}

.logo{
    margin-top: 20px;
    width: 100%;
    height: 62%;
    object-fit: cover;
}
.navButton{
    margin-left: 40rem;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    color: #97d362;
}
.LinkLog{
    color: #000000;
    text-decoration: none;
}

.Cont-PER{
    text-align: end;
}
.per{
    text-align: end;
    width: 6.4rem;
}

/* .peri{
    margin-bottom: 30rem;
    margin-left: 10rem;
} */

/* .MuñecoPer{
    font-size: 3rem;
    margin-left: 1rem;
} */

.Log{
    font-size: 2rem;
    color: black;
}

.IMGPER{
    margin-left: 9rem;
}

.imgloga{
    border-radius: 20px;
    margin-top: -2rem;
    width: 6rem;
    height: 5rem;
}

.pn{
    font-size: 1rem;
    padding: 1rem;
}

.pg{
    font-size: 1rem;
    padding: 1rem;
}

.Actu{
    width: 9rem;
    height: 3rem;
    border-radius: 10px;
    background-color: #97d362;
}

.btnAc{
    display: flex;
    justify-content: center;
    text-align: center;
}

.btnCer{
    width: 6.5rem;
}

.BtnPer{
    
}


.cont-perfil{
}




