.modal2 {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    /* display: flex; */
    align-items: center;
    justify-content: center;
    width: 24rem !important;
    height: 12rem !important;
    margin-left: 53rem;
    border-radius: 30px !important;
    margin-top: 7rem;
  }
  
  .modal-content-login {

  }
  

  
