.canchaContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.canchaWrapper{
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}
.reservaAhora{
    position: absolute;
    top: 10px;
    right: 0;
    border: none;
    padding: 10px 20px;
    background-color: #97d362;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}
.canchaTitle{
    font-size: 24px;

}
.canchaAddress{
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.canchaDistance{
    color: #97d362;
    font-weight: 500;
}

.canchaPriceHighlight{
    color: #3f8800;
    font-weight: 500;
}

.canchaImages{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.canchaImgWrapper{
    width: 33%;
}
.card{
    margin-top: 3rem;
    margin-left: 2.8rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 28rem;
    width: 20rem;
}

.rsu{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.con{
    padding: 1rem;
    font-size: 1.2rem;
    margin-right: 20rem;
}

.pre{
    margin-top: -1.6rem;
    margin-left: 16rem;
}



.val{
    margin-right: 11rem;
}

.Pr{
    font-size: 1rem;
    margin-top: -3rem;
    margin-left: 7rem;
}
.imglog{
    border-radius: 20px;
    margin-top: -4rem;
    width: 6rem;
    height: 5rem;
}
.tarjeta{
    padding: 1rem;
    margin-right: 7rem;
    width: 20rem;
    margin-bottom: 1rem;
}

.pcananchabuy{
    margin: 1.3rem;
}

.ReactModal__Content{
    /* text-align: center;
    justify-content: center;
    align-items: center; */
    width: 27rem !important;
    height: 40rem !important;
    margin-left: 26rem;
    border-radius: 30px !important;

}

.buycan{
    border: none;
    margin: 1rem;
    padding: 10px 20px;
    background-color: #97d362;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 15rem;
    margin-left: 2.5rem;
}

.paycan{
    height: 10rem;
    width: 12rem;
}

.canchaImg{
    width: 100%;
    object-fit: cover;
}

.canchaDetails{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}

.canchaDetailsTexts{
    flex: 3;

}
.pointer{
    cursor: pointer;
    font-size: 2rem;
    background-color: #ebf3ff00;
    border: none;
}
.canchaDesc{
    font-size: 14px;
    margin-top: 20px;
}
.canchaDetailsPrice{
    flex: 1;
    display: flex;
    background-color: #ebf3ff;
    padding: 20px;
    flex-direction:column ;
    gap: 20px;
}

.canchaDetailsPrice>h1{
    font-size: 18px;
    color: #555;
}
.canchaDetailsPrice>span{
    font-size: 14px;
}

.canchaDetailsPrice>h2{
    font-weight: 300;
}
.canchaDetailsPrice>button{
    border: none;
    padding: 10px 20px;
    background-color: #97d362;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.slider{
    position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:#0000006d;
    z-index: 999;
    display: flex;
    align-items: center;
}
.sliderWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sliderImg{
    width: 80%;
    object-fit: cover;
    height:80vh;
}

.close{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #d3d3d3;
    cursor: pointer;
}

.arrow{
    margin: 20px;
    font-size: 50px;
    color: #d3d3d3;
    cursor: pointer;

}

.btnrese{
    border: none;
    padding: 10px 20px;
    background-color: #97d362;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}