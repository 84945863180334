.listContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.listWrapper{
    width: 100%;
    max-width: 1024px;
    display: flex;
    gap: 20px;
}

.listSearch{
    flex:1;
    background-color: #000000;
    padding: 10px;
    border-radius: 10px;
    position: sticky;
    top: 10px;
    height:max-content;
}

.lsTitle{
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 10px;
}

.lsItem{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    
}

.lsItem>label{
    color: #ffffff;
    font-size: 12px;
}

.lsItem>input{
    height: 30px;
    border: none;
    padding: 5px;
}
.lsItem>span{
    height: 30px;
    padding: 5px;
    background-color: #ffffff;
    color: #000000;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.lsOptions{
    padding: 10px;
}
.listResult{
    flex: 3;
    
}
.lsOptionItem{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: rgb(151, 211, 98);
    font-size: 12px;
}

.lsOptionInput{
    width: 50px;
}

.listSearch>button{
    padding: 10px;
    background-color:  #97d362;
    color: #ffffff;
    border: none;
    width: 100%;
    font-weight: 500;
    cursor: pointer;
}


