.leaflet-container{
margin: 2.5%; 
  }

.Bg-Map{
  width: 100%;
  height: 100%;
  background-color: #97d362;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}
.Section-Map{
  width: 35%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius:  50px;
  background: #ffffff;
  margin-right: -5%;

}

.leaflet-container{
  z-index: 0;
}