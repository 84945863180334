.cList{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.cListItem{
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.cListImg{
    width: 100%;
    height: 150px;
    object-fit: cover;   
}

.cListTitles>h1{
font-size: 18px;
}

.cListTitles>h2{
    font-size: 14px;
    font-weight: 300;
    }