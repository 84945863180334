.modalinicio {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 18%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9990;
    background: none;
  }
  
  .modal-content-m1 {
    margin-left: 58rem;
  } 

  .BtnPer{
    display: block;
    width: 6.4rem;
  }


  
